import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { checkLoginStatus } from "../utils/auth";
import Layout from "../utils/Layout";

const Dashboard = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const checkStatus = async () => {
      const result = await checkLoginStatus();
      if (result.success) {
        setLoggedIn(true);
        setUsername(result.username);
      } else {
        navigate("/");
      }
    };

    checkStatus();
  }, [navigate]);

  if (!loggedIn) return null;

  const pages = [
    { name: "Quotes", route: "/quotes" },
    { name: "Profile", route: "/profile" },
    { name: "Settings", route: "/settings" },
  ];

  return (
    <Layout>
      <Grid container spacing={3} justifyContent="center">
        {pages.map((page) => (
          <Grid item xs={12} sm={6} md={4} key={page.name}>
            <Card
              sx={{
                height: "100%",
                backgroundColor: "#ffffff",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <CardActionArea
                onClick={() => navigate(page.route)}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="center">
                    {page.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default Dashboard;
