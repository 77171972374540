import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import QuoteIcon from "@mui/icons-material/FormatQuote";
import { useLocation, useNavigate } from "react-router-dom";
import { handleLogout } from "./auth";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("Dashboard");

  useEffect(() => {
    // Update the page title based on the current path
    const pathToTitle = {
      "/dashboard": "Dashboard",
      "/quotes": "Quotes",
      "/": "Login",
    };
    setPageTitle(pathToTitle[location.pathname] || "My Dashboard");

    // Block scrolling
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [location.pathname]);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsDrawerOpen(false); // Close the drawer after navigation
  };

  const menuItems = [
    { text: "Dashboard", icon: <HomeIcon />, path: "/dashboard" },
    { text: "Quotes", icon: <QuoteIcon />, path: "/quotes" },
    { text: "Login", icon: <QuoteIcon />, path: "/" },
  ];

  const onLogout = async () => {
    await handleLogout();
    navigate("/");
  };

  return (
    <Box>
      {/* Header */}
      <AppBar position="static">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            edge="start"
            sx={{ display: { xs: "block", sm: "none" } }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ textAlign: "center", flexGrow: 1 }}>
            {pageTitle}
          </Typography>
          <Button
            color="inherit"
            onClick={onLogout}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: 250,
          },
        }}
      >
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleNavigation(item.path)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Sidebar for larger screens */}
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          width: "200px",
          height: "calc(100vh - 64px)",
          backgroundColor: "#f0f0f0",
          position: "fixed",
          top: "64px",
          left: 0,
        }}
      >
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleNavigation(item.path)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: "1200px",
          margin: "20px auto",
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          boxShadow: 3,
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          marginLeft: { sm: "200px" },
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
