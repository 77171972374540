import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config";
import Layout from "../utils/Layout"; // Assuming Layout is in utils folder

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [checkingSession, setCheckingSession] = useState(true);
  const navigate = useNavigate();

  // Check session on component mount
  useEffect(() => {
    const checkSession = async () => {
      const storedUsername = localStorage.getItem("username");
      const sessionId = localStorage.getItem("session_id");

      if (storedUsername && sessionId) {
        try {
          const response = await axios.post(
            `${config.apiBaseUrl}/check_session.php`,
            {
              username: storedUsername,
              session_id: sessionId,
            },
            { withCredentials: true }
          );

          if (response.data.success) {
            console.log("Session valid. Navigating to dashboard.");
            navigate("/dashboard");
          } else {
            console.log("Invalid session. Showing login form.");
          }
        } catch (err) {
          console.error("Error checking session:", err);
        }
      }
      setCheckingSession(false); // Mark session check as complete
    };

    checkSession();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/login.php`,
        { username, password },
        { withCredentials: true }
      );

      if (response.data.success) {
        // Store session ID and username in localStorage
        localStorage.setItem("session_id", response.data.session_id);
        localStorage.setItem("username", response.data.username);

        console.log("Success:", response.data.message);
        console.log("Session ID:", response.data.session_id);
        console.log("Username:", response.data.username);

        navigate("/dashboard");
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  if (checkingSession) {
    return (
      <Layout>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <Typography variant="h6">Checking session, please wait...</Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSubmit} style={{ width: "300px" }}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </form>
      </Box>
    </Layout>
  );
};

export default LoginPage;
