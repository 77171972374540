import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../utils/Layout";

const Quotes = () => {
  const [quotes, setQuotes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuotes = async () => {
      const username = localStorage.getItem("username");
      const session_id = localStorage.getItem("session_id");

      if (username && session_id) {
        try {
          const response = await fetch(
            "https://gj.pixolab.in/quotesReact.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ username, session_id }),
            }
          );
          const result = await response.json();
          if (result.success) {
            setQuotes(result.data);
          } else {
            console.error("Error fetching quotes:", result.message);
          }
        } catch (error) {
          console.error("Error fetching quotes:", error);
        }
      } else {
        navigate("/");
      }
    };

    fetchQuotes();
  }, [navigate]);

  const handleSearch = async (event) => {
    const searchQuery = event.target.value;
    const username = localStorage.getItem("username");
    const session_id = localStorage.getItem("session_id");

    try {
      const response = await fetch("https://gj.pixolab.in/quotesReact.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, session_id, search: searchQuery }),
      });
      const result = await response.json();
      if (result.success) {
        setQuotes(result.data);
      } else {
        console.error("Error fetching quotes:", result.message);
      }
    } catch (error) {
      console.error("Error fetching quotes:", error);
    }
  };

  const calculateBalance = (quote) => {
    const { approved_amount, advance_amount, pay1, pay2, pay3 } = quote;
    if (!approved_amount) return "Pending";
    const totalPaid =
      parseFloat(advance_amount || 0) +
      parseFloat(pay1 || 0) +
      parseFloat(pay2 || 0) +
      parseFloat(pay3 || 0);
    return (parseFloat(approved_amount) - totalPaid).toFixed(2) + " OMR";
  };

  return (
    <Layout>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">Quotations for TAC</Typography>
        <Typography variant="body1" color="text.secondary"></Typography>

        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
          <TextField
            variant="outlined"
            placeholder="Search Quotes..."
            onChange={handleSearch}
            sx={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/add_quote")}
            sx={{ marginLeft: 2 }}
          >
            Add New Quote
          </Button>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Quote</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Work Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotes.length > 0 ? (
                quotes.map((quote) => (
                  <TableRow key={quote.id}>
                    <TableCell>{quote.id}</TableCell>
                    <TableCell>{quote.quote_name}</TableCell>
                    <TableCell>
                      {quote.approved_amount
                        ? `${quote.approved_amount} OMR`
                        : `Q ${quote.quoted_amount} OMR`}
                    </TableCell>
                    <TableCell>{calculateBalance(quote)}</TableCell>
                    <TableCell>{quote.status}</TableCell>
                    <TableCell>{quote.work_status}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate(`/edit_quote/${quote.id}`)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No quotes available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
};

export default Quotes;
